import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				ARTPOTTERY
			</title>
			<meta name={"description"} content={"ArtPottery надає можливість поринути у світ керамічного мистецтва через наші захоплюючі та інтерактивні майстер-класи."} />
			<meta property={"og:title"} content={"ARTPOTTERY"} />
			<meta property={"og:description"} content={"ArtPottery надає можливість поринути у світ керамічного мистецтва через наші захоплюючі та інтерактивні майстер-класи."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header12>
		<Section background="#ffffff" padding="90px 0 110px 0" quarkly-title="Team-12">
			<Text margin="0px 0px 0px 0px" color="--dark" font="--headline1" text-align="center">
				Наші пропозиції
			</Text>
			<Text
				text-align="center"
				color="--darkL2"
				font="--lead"
				margin="16px auto 16px auto"
				lg-margin="16px 0 16px 0px"
			>
				У ArtPottery ми пишаємося тим, що пропонуємо широкий асортимент пропозицій для задоволення всіх ваших гончарних потреб. Незалежно від того, чи ви новачок, чи досвідчений митець, наші комплексні пропозиції створені, щоб надати вам найкращий досвід. Пориньте в наші пропозиції та дізнайтеся, як ми можемо допомогти вам створити щось справді особливе.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				flex-direction="column"
				lg-width="100%"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="repeat(2, 1fr)"
				grid-gap="46px 24px"
				sm-grid-template-columns="1fr"
				sm-grid-template-rows="auto"
				sm-grid-gap="36px 0"
				margin="50px 0px 0px 0px"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
				align-items="center"
				align-self="center"
				justify-items="center"
				align-content="center"
				justify-content="center"
			>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Warsztaty_ceramiczne_-1.jpg?v=2024-09-20T07:30:04.732Z"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="100%"
							srcSet="https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Warsztaty_ceramiczne_-1.jpg?v=2024-09-20T07%3A30%3A04.732Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Warsztaty_ceramiczne_-1.jpg?v=2024-09-20T07%3A30%3A04.732Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Warsztaty_ceramiczne_-1.jpg?v=2024-09-20T07%3A30%3A04.732Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Warsztaty_ceramiczne_-1.jpg?v=2024-09-20T07%3A30%3A04.732Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Warsztaty_ceramiczne_-1.jpg?v=2024-09-20T07%3A30%3A04.732Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Warsztaty_ceramiczne_-1.jpg?v=2024-09-20T07%3A30%3A04.732Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Warsztaty_ceramiczne_-1.jpg?v=2024-09-20T07%3A30%3A04.732Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="--headline3" lg-margin="0 0px 0px 0px" margin="0 0px 2rem 0px">
							Уроки гончарства для всіх рівнів кваліфікації
						</Text>
						<Text margin="8px 0px 0px 0px" color="--darkL2" font="300 18px/140% --fontFamily-sansHelvetica">
							Наші заняття з гончарства є серцем ArtPottery. Ми пропонуємо різноманітні заняття для всіх рівнів, від початківця до професіонала.
							<br />
							Класи для початківців: ідеально для тих, хто починає. Вивчайте основи ручного формування та роботи на гончарному крузі.
							<br />
							Класи середнього рівня: удосконалюйте свої навички та досліджуйте нові техніки, включаючи декорування поверхні та функціональну кераміку.
							<br />
							Просунуті класи: для досвідчених гончарів, фокусуючись на складних техніках, як-от скульптурна кераміка та майстерність роботи на крузі.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="--headline3" lg-margin="0 0px 0px 0px" margin="0 0px 2rem 0px">
							Спеціалізовані семінари та заходи
						</Text>
						<Text margin="8px 0px 0px 0px" color="--darkL2" font="300 18px/140% --fontFamily-sansHelvetica">
							У нас ви знайдете семінари, натхненні сезонними подіями, а також спеціальні майстер-класи від запрошених художників.
							<br />
							Сезонні майстер-класи: від виготовлення святкових прикрас до створення літньої кераміки для саду.
							<br />
							Серія запрошених художників: навчайтесь у відомих гончарів та дізнайтесь їхні унікальні підходи до мистецтва.
							<br />
							Сімейні заходи: організуйте гончарний день для всієї родини або візьміть участь у майстер-класах для команд.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery-Class-Singapore-Cover-Image-Opt-1024x683.jpg?v=2024-09-20T07:30:04.720Z"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="100%"
							srcSet="https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery-Class-Singapore-Cover-Image-Opt-1024x683.jpg?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery-Class-Singapore-Cover-Image-Opt-1024x683.jpg?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery-Class-Singapore-Cover-Image-Opt-1024x683.jpg?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery-Class-Singapore-Cover-Image-Opt-1024x683.jpg?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery-Class-Singapore-Cover-Image-Opt-1024x683.jpg?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery-Class-Singapore-Cover-Image-Opt-1024x683.jpg?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery-Class-Singapore-Cover-Image-Opt-1024x683.jpg?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				flex-direction="column"
				lg-width="100%"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="repeat(2, 1fr)"
				grid-gap="46px 24px"
				sm-grid-template-columns="1fr"
				sm-grid-template-rows="auto"
				sm-grid-gap="36px 0"
				margin="50px 0px 0px 0px"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
			>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery_Workshops_Berlin_1_1024x1024.webp?v=2024-09-20T07:30:04.720Z"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="100%"
							max-height="100%"
							srcSet="https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery_Workshops_Berlin_1_1024x1024.webp?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery_Workshops_Berlin_1_1024x1024.webp?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery_Workshops_Berlin_1_1024x1024.webp?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery_Workshops_Berlin_1_1024x1024.webp?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery_Workshops_Berlin_1_1024x1024.webp?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery_Workshops_Berlin_1_1024x1024.webp?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery_Workshops_Berlin_1_1024x1024.webp?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
					align-self="center"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="--headline3" lg-margin="0 0px 0px 0px" margin="0 0px 2rem 0px">
							Приватні уроки та членство в студії
						</Text>
						<Text margin="8px 0px 0px 0px" color="--darkL2" font="300 18px/140% --fontFamily-sansHelvetica">
							Для тих, хто шукає персоналізований досвід, ми пропонуємо приватні уроки та членство в студії з 24/7 доступом та знижками на матеріали.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
					align-self="center"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="--headline3" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
							Зв’яжіться з нами, щоб дізнатися більше
						</Text>
						<Text margin="8px 0px 0px 0px" color="--darkL2" font="300 18px/140% --fontFamily-sansHelvetica">
							Зв'яжіться з нами сьогодні, щоб дізнатися більше про наші пропозиції та як ми можемо допомогти вам у вашій гончарній подорожі.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://images.unsplash.com/photo-1520408222757-6f9f95d87d5d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="100%"
							srcSet="https://images.unsplash.com/photo-1520408222757-6f9f95d87d5d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1520408222757-6f9f95d87d5d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1520408222757-6f9f95d87d5d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1520408222757-6f9f95d87d5d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1520408222757-6f9f95d87d5d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1520408222757-6f9f95d87d5d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1520408222757-6f9f95d87d5d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer12>
			<Override slot="link4" />
		</Components.Footer12>
		<Components.Policy />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});